#noGridInput {
    margin: 15px 0 0 0;
}

#onBoardingHeading {
    color: var(--cobuDarkBlue);
    margin: 27px 0 13px 0;

}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.item {
    display: flex;
    flex-direction: column;
}

.title {
    color: var(--cobuDarkBlue);
}

.errorTitle {
    color: var(--cobuError);
}

.nonEditable {
    background-color: lightgray;
}

.twoRow {
    display: flex;
    justify-content: space-between;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.formHeader {
    display: flex;
    justify-content: space-between;
}

.formButtons {
    display: flex;
    justify-content: flex-end;
}

.radioInpuContainer {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--cobuLightGreyPointTwo);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 18px;
}

.disabled {
    opacity: 0.3;
    border-color: var(--cobuDarkBlueDisable);
}

.rsvpRequiredContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 24px;
    grid-template-areas: 'rsvpRequired attendees';
}

.rsvpRequired {
    grid-area: rsvpRequired;
}

.attendees {
    grid-area: attendees;
}

p {
    font-size: 14px;
    line-height: 16px;
    color: rgba(39, 33, 99, 0.5);
}


.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rowTextStyle {
    display: flex;
    flex: 60%;
    background-color: 'red';
    color: var(--cobuDarkBlue);
}

.switchStyle {
    transform: scale(0.6)
}

.additional-url-container {
    margin: 10px 0
}

#building-form {
    overflow: 'auto';
    height: 75%
}

.review-description {
    margin: 4px 0 0 0;
}

#form-container {
    overflow: 'auto';
}

.create-container { 
    padding: 20px 20px 20px 20px;
}

.first-section-heading {
    color: var(--cobuDarkBlue);
}

.section-heading {
    color: var(--cobuDarkBlue);
    margin: 100px 0 30px 0;
}

.createEditBuildingForm {
    display: flex;
    flex-direction: column;
}

.section-container {
    padding: 20px;
    background-color: var(--cobuLightGreyPointSix);
    border: 2px dashed var(--cobuLightGreyPointTwo);
}

.sticky-header {
    background-color: var(--cobuYellow);
}

.sticky-header-container {
    position: sticky;
    z-index: 1;
    top: 0px;
    padding: 20px 0 0 0;
    background-color: var(--cobuWhite);
}

.header-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header-list-divider {
    height: 50px;
    width: 2px;
    background-color: var(--cobuDarkBlue);
}

.header-list-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.tab-focused {
    background-color: var(--cobuLightBlue);
    border: 2px solid var(--cobuDarkBlue);
    border-radius: 8px;
    box-shadow: 0 0 5px var(--cobuDarkBlue);
}