.container {
  padding: 20px 50px
}

.sourceContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 20px;
}

.rowContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
}

.textButton {
  min-width: unset;
  min-height: unset;
  width: fit-content;
  border-radius: 10px;
}


.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.commentsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.videoTableContainer {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 24px;
  width: 100%;

  h2 {
    color: #272163;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  /* Switch styling */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked+.slider {
        background-color: #4CAF50;
      }

      &:focus+.slider {
        box-shadow: 0 0 1px #4CAF50;
      }

      &:checked+.slider:before {
        transform: translateX(26px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }


  .videoTableContainer {
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 24px;
    width: 100%;

    h2 {
      color: #272163;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }

  .addVideoContainer {
    display: flex;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #eaeaea;

    .videoUrlInput {
      flex: 1;
      padding: 10px 12px;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      margin-right: 12px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: #272163;
        box-shadow: 0 0 0 2px rgba(39, 33, 99, 0.1);
      }
    }
  }
}