:root {
  --cobuDarkBlue: #272163;
  --cobuWhite: white;
  --cobuYellow: #fec100;
  --cobuLightGrey: #f9f8fa;
  --cobuGreen: rgba(52, 236, 22, 1);

  --cobuLightGreyPointZeroThree: rgba(39, 33, 99, 0.03);
  --cobuLightGreyPointZeroFive: rgba(39, 33, 99, 0.05);
  --cobuLightGreyPointOne: rgba(39, 33, 99, 0.1);
  --cobuLightGreyPointTwo: rgba(39, 33, 99, 0.2);
  --cobuLightGreyPointThree: rgba(39, 33, 99, 0.3);
  --cobuLightGreyPointFive: rgba(39, 33, 99, 0.5);
  --cobuLightGreyPointSix: rgba(248, 248, 248, 1);
  --cobuLightGreyPointSeven: rgba(173, 174, 181, 1);
  --cobuLightGreyPointEigth: rgba(221, 221, 221, 1);
  --cobuDarkBlueDisable: rgba(118, 118, 118, 0.3);
  --cobuBlack50: #00000080;

  --cobuBlack: #171341;
  --cobuTurquoise: #16bbd2;
  --cobuTurquoiseLight: rgba(22, 187, 210, 0.8);
  --cobuRed: #ec1659;
  --cobuPurple: #a031e4;
  --cobuWarning: #e45a5a;
  --cobuLightLightGrey: #504a8a;
  --cobuError: #ef4a4a;
  --cobuErrorPointOne: rgba(239, 74, 74, 0.1);
  --iconInfoWarning: #fe8900;
  --tooltipText: #fcfbfd;

  --headerHeight: 80px;
  --footerHeight: 85px;

  --zIndexDropdown: 2;
  --zIndexModal: 3;
  --cobuLightYellow: #fff5d7;
}
