.no-referral-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lead-management-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.manage-lead {
  text-decoration: underline;
}

.manage-lead-comment {
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
  overflow: hidden
}