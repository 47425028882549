.otherCCIContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 40px;
}

.cciContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 40px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
}

.optionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;    
}

.otherCCIOption {
    padding: 20px;
    border-radius: 10px;
    background-color: var(--cobuLightGreyPointEigth);
}

.container {
    padding: 40px;
}

.answerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}