.container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subtitleContainer {
  margin-top: 18px;
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: var(--cobuDarkBlue);
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}

.onBoardingContainer {
  padding: 40px 102px;;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cobuLightYellow);
  border-left: 20px solid var(--cobuYellow);
}

.onBoardingTitle {
  font-size: 32px;
  color: var(--cobuYellow);

}

.onBoardingRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}

.completeOnBoarding {
  color: var(--cobuWhite);
  background-color: var(--cobuTurquoise);
}