#noGridInput {
    margin: 15px 0 0 0;
}

#onBoardingHeading {
    color: var(--cobuDarkBlue);
    margin: 27px 0 13px 0;

}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.item {
    display: flex;
    flex-direction: column;
}

.title {
    color: var(--cobuDarkBlue);
}

.errorTitle {
    color: var(--cobuError);
}

.nonEditable {
    background-color: lightgray;
}

.twoRow {
    display: flex;
    justify-content: space-between;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.formHeader {
    display: flex;
    justify-content: space-between;
}

.formButtons {
    display: flex;
    justify-content: flex-end;
}

.radioInpuContainer {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--cobuLightGreyPointTwo);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 18px;
}

.disabled {
    opacity: 0.3;
    border-color: var(--cobuDarkBlueDisable);
}

.rsvpRequiredContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0px 24px;
    grid-template-areas: 'rsvpRequired attendees';
}

.rsvpRequired {
    grid-area: rsvpRequired;
}

.attendees {
    grid-area: attendees;
}

p {
    font-size: 14px;
    line-height: 16px;
    color: rgba(39, 33, 99, 0.5);
}


.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rowTextStyle {
    display: flex;
    flex: 60%;
    background-color: 'red';
    color: var(--cobuDarkBlue);
}

.switchStyle {
    transform: scale(0.6)
}

.additional-url-container {
    margin: 10px 0
}

#building-form {
    overflow: 'auto';
    height: 75%
}

.review-description {
    margin: 4px 0 0 0;
}

#form-container {
    overflow: 'auto';
}